/* global angular, gData, config, moment */

'use strict';

angular.module('managerApp').controller('PartnerLeftSidebarController', (
        $rootScope, $scope, $cookies, $mdSidenav, $mdDialog, toast, $_webapps, $_procedures) => {

    $scope.isPartnerSession = config.isPartnerSession;
    $scope.client = $rootScope.currentUser;
    if ($scope.client && $scope.isPartnerSession === true) {
        $scope.hasPrepaidAccount = $scope.client.PrepaidAccount !== null;
        $scope.isTerminalPartner = $scope.client.TerminalPartner !== null;
        $scope.isTabletPartner = $scope.client.TabletPartner !== null;
        $scope.isWebAppPartner = $scope.client.WebAppPartner !== null;
        //if ($scope.isWebAppPartner === true) {
            let webAppToken = $cookies.get(config.app.cookies.webappToken);
            if (!webAppToken) {
                $scope.showActivateWebAppMenu = true;
            }
        //}
        $scope.isSmarty = $scope.client._id === 'a1ddb757-3076-4e85-b443-5089d71d2918';
        $scope.isFeuVert = $scope.client._id === '2349fe7f-976a-4ca5-8b07-6afe0f8ae7f9';
        $scope.showWebAppMenu = true;
    }

    $scope.$on('toggleMenu', () => $mdSidenav('left').toggle());
    $scope.openSidenav = () => $mdSidenav('left-sidebar').toggle();

    $scope.showLogoutPopup = ($event) => {
        $mdDialog.show({
            targetEvent: $event,
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/templates/logout.html',
            controller: ($scope, $location, $mdDialog) => {
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $location.path('/logout');
                    }
                    $mdDialog.cancel();
                };
            }
        });
    };

    $scope.showWebAppActivationPopup = ($event) => {
        $mdDialog.show({
            targetEvent: $event,
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/templates/activate.webapp.html',
            controller: ($scope, $mdDialog) => {
                $scope.validate = () => {
                    $_webapps.activateWebApp($scope.activationCode).then((res) => {
                        $mdDialog.cancel();
                        $cookies.put(config.app.cookies.webappToken, res.webAppToken);
                        toast.show("La web app a bien été activée." + "<br>" +
                                "Veuillez réactualiser la page.", 'success');
                    }).catch((err) => {
                        toast.show(err, 'error');
                    });
                };
                $scope.closeDialog = () => {
                    $mdDialog.cancel();
                };
            }
        });
    };

    //--------------------------------------------------------------------------

});